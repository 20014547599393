import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./Four.scss"

import LogoGif from "../../img/media/logo.gif"

import About1 from "-!svg-react-loader?props[]=className:santa-logo!../../img/about1.svg"
import About2 from "-!svg-react-loader?props[]=className:santa-logo!../../img/about2.svg"


const Four = () => {
    const data = useStaticQuery(graphql`
    query {
        About3: file(relativePath: {eq: "about3.jpg"}) {
            childImageSharp {
                    fluid(maxWidth: 330, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                        ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        } 
    }
    `)

    return (
        <div>
            <section class="hero is-medium">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-two-thirds has-text-centered">
                                <img src={LogoGif} alt="Santa Walt" style={{ maxWidth: "300px" }} />
                                <p></p>
                                &nbsp;
                                <p class="subtitle is-size-1 is-size-2-touch has-text-weight-bold">4<span className="alt-title">0</span>4</p>
                                <p className="is-size-5">Oops.. It looks like this page doesnt exist.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Four